body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.track-img{
  width: 23px;
  margin-right: 12px;
}
.track-body{
  height: 45px;
}
.track-mobile-icon{
  display: inline-block;
}
.track-mobile-icon g,
.track-mobile-icon path{
  opacity: 1 !important;
}
.wrapper-icon{
  width: 45px;
  display: inline-block;
  text-align: center;
}
.wrapper-icon svg{
  width: 25px;
  height: 25px;
}
@media screen and (max-width: 359px) and (min-width: 300px) {
  .track-image{
    width: 20px;
  }
  .track-icon-container svg{
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 459px) and (min-width: 360px) {
  .track-image{
    width: 30px;
  }
  .track-icon-container svg{
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 559px) and (min-width: 460px) {
  .track-image{
    width: 35px;
  }
  .track-icon-container svg{
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 639px) and (min-width: 560px) {
  .track-image{
    width: 40px;
  }
  .track-icon-container svg{
    width: 60px;
    height: 60px;
  }
}
/*
.header-social-media-icons {
  width: 25px;
  height: 25px;
}

.ui.menu .item {
  padding-top: 19px;
  padding-bottom: 18px;
}
*/

