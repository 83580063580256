@font-face {
	font-family: "el-icon";
	src: url("el-icon.eot?4613feb4c35518300cbdc7353134e1df?#iefix") format("embedded-opentype"),
url("el-icon.woff2?4613feb4c35518300cbdc7353134e1df") format("woff2"),
url("el-icon.woff?4613feb4c35518300cbdc7353134e1df") format("woff"),
url("el-icon.ttf?4613feb4c35518300cbdc7353134e1df") format("truetype"),
url("el-icon.svg?4613feb4c35518300cbdc7353134e1df#el-icon") format("svg");
}

.el-icon {
	line-height: 1;
}

.el-icon:before {
	font-family: el-icon !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.el-icon-Add-bag-32:before {
	content: "\f101";
}
.el-icon-Bell:before {
	content: "\f102";
}
.el-icon-Path_399:before {
	content: "\f103";
}
.el-icon-add-circled:before {
	content: "\f104";
}
.el-icon-add:before {
	content: "\f105";
}
.el-icon-ask:before {
	content: "\f106";
}
.el-icon-back:before {
	content: "\f107";
}
.el-icon-bank:before {
	content: "\f108";
}
.el-icon-bell-line:before {
	content: "\f109";
}
.el-icon-buy-now:before {
	content: "\f10a";
}
.el-icon-by-shop:before {
	content: "\f10b";
}
.el-icon-calendar:before {
	content: "\f10c";
}
.el-icon-cancel:before {
	content: "\f10d";
}
.el-icon-cash:before {
	content: "\f10e";
}
.el-icon-check:before {
	content: "\f10f";
}
.el-icon-close:before {
	content: "\f110";
}
.el-icon-copy:before {
	content: "\f111";
}
.el-icon-credit:before {
	content: "\f112";
}
.el-icon-customer:before {
	content: "\f113";
}
.el-icon-delete-circled:before {
	content: "\f114";
}
.el-icon-delete-solid:before {
	content: "\f115";
}
.el-icon-delete:before {
	content: "\f116";
}
.el-icon-discount:before {
	content: "\f117";
}
.el-icon-done:before {
	content: "\f118";
}
.el-icon-down-arrow-gray:before {
	content: "\f119";
}
.el-icon-down-arrow:before {
	content: "\f11a";
}
.el-icon-edit-lined:before {
	content: "\f11b";
}
.el-icon-edit:before {
	content: "\f11c";
}
.el-icon-education:before {
	content: "\f11d";
}
.el-icon-error:before {
	content: "\f11e";
}
.el-icon-facebook:before {
	content: "\f11f";
}
.el-icon-faq:before {
	content: "\f120";
}
.el-icon-filter-filled:before {
	content: "\f121";
}
.el-icon-flat_percent:before {
	content: "\f122";
}
.el-icon-flat_rate:before {
	content: "\f123";
}
.el-icon-follow:before {
	content: "\f124";
}
.el-icon-fortuen-gift:before {
	content: "\f125";
}
.el-icon-free-percentage:before {
	content: "\f126";
}
.el-icon-free-shipping:before {
	content: "\f127";
}
.el-icon-free_shipping:before {
	content: "\f128";
}
.el-icon-gift-promo:before {
	content: "\f129";
}
.el-icon-gift:before {
	content: "\f12a";
}
.el-icon-google-plus:before {
	content: "\f12b";
}
.el-icon-google:before {
	content: "\f12c";
}
.el-icon-health:before {
	content: "\f12d";
}
.el-icon-heart-filled:before {
	content: "\f12e";
}
.el-icon-heart:before {
	content: "\f12f";
}
.el-icon-home-made:before {
	content: "\f130";
}
.el-icon-hot-deals:before {
	content: "\f131";
}
.el-icon-info-1:before {
	content: "\f132";
}
.el-icon-info:before {
	content: "\f133";
}
.el-icon-invite:before {
	content: "\f134";
}
.el-icon-join:before {
	content: "\f135";
}
.el-icon-left:before {
	content: "\f136";
}
.el-icon-list:before {
	content: "\f137";
}
.el-icon-logo-ar:before {
	content: "\f138";
}
.el-icon-logo-en:before {
	content: "\f139";
}
.el-icon-loyalty:before {
	content: "\f13a";
}
.el-icon-notifications:before {
	content: "\f13b";
}
.el-icon-off-and-shipping:before {
	content: "\f13c";
}
.el-icon-offer-label-tag:before {
	content: "\f13d";
}
.el-icon-offers:before {
	content: "\f13e";
}
.el-icon-online:before {
	content: "\f13f";
}
.el-icon-orders:before {
	content: "\f140";
}
.el-icon-payment:before {
	content: "\f141";
}
.el-icon-phone:before {
	content: "\f142";
}
.el-icon-pickup:before {
	content: "\f143";
}
.el-icon-privacy:before {
	content: "\f144";
}
.el-icon-products:before {
	content: "\f145";
}
.el-icon-profile:before {
	content: "\f146";
}
.el-icon-promo:before {
	content: "\f147";
}
.el-icon-q-post:before {
	content: "\f148";
}
.el-icon-question:before {
	content: "\f149";
}
.el-icon-refund-details:before {
	content: "\f14a";
}
.el-icon-refund:before {
	content: "\f14b";
}
.el-icon-remove:before {
	content: "\f14c";
}
.el-icon-reset:before {
	content: "\f14d";
}
.el-icon-right-arrow:before {
	content: "\f14e";
}
.el-icon-right-message:before {
	content: "\f14f";
}
.el-icon-right:before {
	content: "\f150";
}
.el-icon-rocket:before {
	content: "\f151";
}
.el-icon-search:before {
	content: "\f152";
}
.el-icon-security:before {
	content: "\f153";
}
.el-icon-setting:before {
	content: "\f154";
}
.el-icon-shops:before {
	content: "\f155";
}
.el-icon-smart:before {
	content: "\f156";
}
.el-icon-star-empty:before {
	content: "\f157";
}
.el-icon-star:before {
	content: "\f158";
}
.el-icon-terms:before {
	content: "\f159";
}
.el-icon-twitter:before {
	content: "\f15a";
}
.el-icon-up-arrow-gray:before {
	content: "\f15b";
}
.el-icon-upload:before {
	content: "\f15c";
}
.el-icon-view:before {
	content: "\f15d";
}
.el-icon-zoom:before {
	content: "\f15e";
}
